import type { NextPage } from 'next'
import { useRouter } from 'next/router'

const Home: NextPage = () => {
  const router = useRouter()
  router.push('/tickets')
  return (
    <div className="bg-stoat flex h-screen items-center justify-center overflow-hidden">
      Loading...
    </div>
  )
}

export default Home
